// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-justkiddinntoo-about-us-js": () => import("./../src/pages/justkiddinntoo/about-us.js" /* webpackChunkName: "component---src-pages-justkiddinntoo-about-us-js" */),
  "component---src-pages-justkiddinntoo-gallery-js": () => import("./../src/pages/justkiddinntoo/gallery.js" /* webpackChunkName: "component---src-pages-justkiddinntoo-gallery-js" */),
  "component---src-pages-justkiddinntoo-js": () => import("./../src/pages/justkiddinntoo.js" /* webpackChunkName: "component---src-pages-justkiddinntoo-js" */),
  "component---src-pages-justkiddinntoo-parent-login-js": () => import("./../src/pages/justkiddinntoo/parent-login.js" /* webpackChunkName: "component---src-pages-justkiddinntoo-parent-login-js" */),
  "component---src-pages-justkiddinntoo-philosophy-js": () => import("./../src/pages/justkiddinntoo/philosophy.js" /* webpackChunkName: "component---src-pages-justkiddinntoo-philosophy-js" */),
  "component---src-pages-kiddinn-about-us-js": () => import("./../src/pages/kiddinn/about-us.js" /* webpackChunkName: "component---src-pages-kiddinn-about-us-js" */),
  "component---src-pages-kiddinn-gallery-js": () => import("./../src/pages/kiddinn/gallery.js" /* webpackChunkName: "component---src-pages-kiddinn-gallery-js" */),
  "component---src-pages-kiddinn-js": () => import("./../src/pages/kiddinn.js" /* webpackChunkName: "component---src-pages-kiddinn-js" */),
  "component---src-pages-kiddinn-parent-login-js": () => import("./../src/pages/kiddinn/parent-login.js" /* webpackChunkName: "component---src-pages-kiddinn-parent-login-js" */),
  "component---src-pages-kiddinn-philosophy-js": () => import("./../src/pages/kiddinn/philosophy.js" /* webpackChunkName: "component---src-pages-kiddinn-philosophy-js" */),
  "component---src-pages-littleseeds-about-us-js": () => import("./../src/pages/littleseeds/about-us.js" /* webpackChunkName: "component---src-pages-littleseeds-about-us-js" */),
  "component---src-pages-littleseeds-gallery-js": () => import("./../src/pages/littleseeds/gallery.js" /* webpackChunkName: "component---src-pages-littleseeds-gallery-js" */),
  "component---src-pages-littleseeds-js": () => import("./../src/pages/littleseeds.js" /* webpackChunkName: "component---src-pages-littleseeds-js" */),
  "component---src-pages-littleseeds-parent-login-js": () => import("./../src/pages/littleseeds/parent-login.js" /* webpackChunkName: "component---src-pages-littleseeds-parent-login-js" */),
  "component---src-pages-littleseeds-philosophy-js": () => import("./../src/pages/littleseeds/philosophy.js" /* webpackChunkName: "component---src-pages-littleseeds-philosophy-js" */)
}

