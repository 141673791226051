import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Header from "./header"

const Layout = ({ children, location }) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.1/jquery.min.js"
    script.async = true

    document.body.appendChild(script)

    const scriptGm = document.createElement("script")

    scriptGm.src =
      "https://maps.googleapis.com/maps/api/js?v=3.exp&signed_in=true&libraries=places&key=AIzaSyAwIpAZbeIZJArddCbf5LiWXYAZYlN1iyA"
    scriptGm.async = true

    document.body.appendChild(scriptGm)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(scriptGm)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      headerLogoKiddInn: file(relativePath: { eq: "kidd-inn-new-logo.png" }) {
        childImageSharp {
          fixed(width: 218) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      littleSeeds: file(
        relativePath: { eq: "little-seeds-white-bg-with-text-new.png" }
      ) {
        childImageSharp {
          fixed(width: 218) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const baseUrlSegment = location && location.pathname.split("/")[1]
  const headerImage = () => {
    switch (baseUrlSegment) {
      case "kiddinn":
        return data.headerLogoKiddInn.childImageSharp.fixed
      case "littleseeds":
        return data.littleSeeds.childImageSharp.fixed
      default:
        return data.headerLogoKiddInn.childImageSharp.fixed
    }
  }
  return (
    <div className="background-light twlsky-red twlsky-mixedcolours isolated-sections loader-bar">
      <div className="outer-container clearfix">
        {location && location.pathname !== "/" && (
          <Header
            siteTitle={data.site.siteMetadata.title}
            headerImage={headerImage()}
            centerUrl={baseUrlSegment}
          />
        )}
        <div className="inside-body-content-container clearfix">
          {children}
          <footer>
            <div className="container clearfix">
              <div className="row">
                <div className="col-sm-12">
                  {location && location.pathname !== "/" && (
                    <Link to="/">Back To Centre Selection</Link>
                  )}
                  <div>© {new Date().getFullYear()}, Kidd Inn</div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
