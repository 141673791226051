import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Img from "gatsby-image"

const Header = ({ headerImage, centerUrl }) => {
  const [showMenuMobile, showMenuMobileToggle] = useState(false)
  return (
    <header className="clearfix">
      <div className="text-center">
        <Link to={centerUrl}>
          <Img fixed={headerImage} />
        </Link>
      </div>

      <div id="main-menu-container" className="clearfix">
        <div
          id="mobile-menu-icon"
          className="btn btn-outline-inverse visible-xs"
          role="button"
          onClick={() => showMenuMobileToggle(!showMenuMobile)}
        >
          <span className="glyphicon glyphicon-th"></span>
        </div>

        <ul
          id="main-menu"
          style={showMenuMobile ? { display: `block` } : null}
          className="hidden-sm hidden-md hidden-lg"
        >
          <li
            className="menu-item"
            onClick={() => showMenuMobileToggle(!showMenuMobile)}
          >
            <Link to={centerUrl}>Home</Link>
          </li>
          <li
            className="menu-item"
            onClick={() => showMenuMobileToggle(!showMenuMobile)}
          >
            <Link to={`${centerUrl}/about-us`}>About Us</Link>
          </li>
          <li
            className="menu-item"
            onClick={() => showMenuMobileToggle(!showMenuMobile)}
          >
            <Link to={`${centerUrl}/philosophy`}>Philosophy</Link>
          </li>
          <li
            className="menu-item"
            onClick={() => showMenuMobileToggle(!showMenuMobile)}
          >
            <Link to={`${centerUrl}/gallery`}>Gallery</Link>
          </li>
        </ul>

        <ul id="main-menu" className="hidden-xs">
          <li className="menu-item">
            <Link to={centerUrl}>Home</Link>
          </li>
          <li className="menu-item">
            <Link to={`${centerUrl}/about-us`}>About Us</Link>
          </li>
          <li className="menu-item">
            <Link to={`${centerUrl}/philosophy`}>Philosophy</Link>
          </li>
          <li className="menu-item">
            <Link to={`${centerUrl}/gallery`}>Gallery</Link>
          </li>
          <li className="menu-item">
            <Link to={`${centerUrl}/parent-login`}>Parent Login</Link>
          </li>
        </ul>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
