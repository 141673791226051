/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/bootstrap/css/bootstrap.min.css"
import "./src/styles/font-awesome/css/font-awesome.min.css"
import "./src/styles/css/style-theme.min.css"
import "./src/styles/css/style-colours.min.css"
import "./src/styles/css/style-mixedcolours.min.css"
import "./src/styles/css/loader.css"
import "./src/styles/css/styles.css"

import CustomLayout from "./wrapPageElement"
export const wrapPageElement = CustomLayout
